.footer {
  margin-top: 3rem;
  padding: 1.5rem 1rem 1rem;

  font-size: 0.8em;

  background-color: var(--colour-primary);

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  div {
    margin: 0.5rem;
  }
}