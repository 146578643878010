.privacyPolicy, .privacyPolicyBackground {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 !important;
}

.privacyPolicyBackground {
  z-index: 100;
  background-color: #222222aa;
}

.privacyPolicy {
  z-index: 101;

  position: fixed;
  left: auto;
  right: auto;
  top: 3rem;
  bottom: auto;

  margin: 1rem;
  padding: 0.5rem 1rem;
  max-width: 30rem;

  box-shadow: 0.5rem 0.5rem black;
  background-color: var(--colour-primary);

  .close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;

    background: none;
    border: none;

    cursor: pointer;
    font-size: 1.8em;

    color: white;
    padding: 0;
    margin: 1rem 0.5rem;
  }
}