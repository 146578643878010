.hero {
  position: relative;

  margin: auto;
  padding: 4rem 4rem 0 4rem;
  max-width: 90rem;

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  .image {
    margin: 0 1rem 0 0;
    max-width: 70vw;

    > span {
      box-shadow: 1rem 1rem black;
    }
  }
  
  .introduction {
    text-align: left;
    padding: 1rem 2rem 0;
    width: 400px;
    flex-grow: 1;

    ul {
      padding-left: 0;
    }
    li {
      list-style-type: none;
    }
  
    em {
      font-style: normal;
      font-weight: bold;
      font-size: 1.1em;
    }
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    padding: 1rem 2rem 0;

    .image {
      margin: 0 auto;
    }

    .introduction {
      padding: 2rem 0rem;
      text-align: center;
    }

    li {
      margin-bottom: 0.6rem;
    }
  }
}