.card {
  background-color: var(--colour-secondary);
  margin: 1rem;

  display: flex;
  flex-direction: column;
  position: relative;

  box-shadow: 0.5rem 0.5rem black;
  width: 15rem;
  max-width: 30rem;
  flex-grow: 1;

  overflow: hidden;

  .content {
    padding: 1rem;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    h3 {
      margin-top: 0;
      margin-bottom: 0.3rem;
    }

    p {
      margin-top: 0;
      font-size: 0.9em;
    }
  }

  @media print {
    box-shadow: none;
    border: 0.1rem solid black;
  }
}

.anchorCard {
  cursor: pointer;

  &:hover {
    box-shadow: 0.8rem 0.8rem black;
    transform: translate(-0.1rem, -0.1rem);
  }

  .mainAnchor {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.largeCard {
  width: 25rem;
  max-width: 35rem;
}