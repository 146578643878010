.tag {
  background-color: grey;
  font-size: 0.75em;

  margin: 0.2rem 0.3rem;
  padding: 0 0.3rem;

  border: 0.1rem solid white;

  display: flex;
  align-items: center;

  svg {
    margin-right: 0.3rem;
  }
}