.about {
  position: relative;
  display: flex;
  flex-direction: row;

  max-width: 80rem;
  margin: 0 auto;
  padding: 0 6rem 0 6rem;
  scroll-margin-top: 5em;
}

.left {
  text-align: left;
  width: 40rem;
  flex-grow: 1;
}

.right {
  width: 20rem;
  padding: 1rem 2rem;
  margin: 2rem;

  position: relative;
  top: -2rem;
  text-align: left;

  h3 {
    margin-bottom: 0.3rem;
  }
  ul {
    margin-top: 0;
  }
}

.rightBackground {
  background-color: var(--colour-secondary);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: -100vw;
  border-radius: 1rem;
  z-index: -1;

  box-shadow: 1rem 1rem black;

  @media print {
    box-shadow: none;
  }
}

@media only screen and (max-width: 1200px) {
  .about {
    flex-direction: column;
  }

  .left {
    width: auto;
  }
  .right {
    margin: 2rem auto;
    width: inherit;

  }

  .rightBackground {
    right: 0;
  }
}

@media only screen and (max-width: 600px) {
  .about {
    padding: 0 2rem;
  }
}