.header {
  min-height: 4rem;
  padding: 1rem 0.5rem 0.5rem;

  display: flex;

  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  transition: .2s;

  nav {
    display: flex;
    flex-grow: 1;

    ul {
      padding-left: 1rem;
    }

    a {
      color: white;

      &:hover {
        text-shadow: 0.12rem 0.12rem var(--colour-primary-darker);
      }
    }

    li:hover {
      transform: translate(-0.04rem, -0.04rem);
    }
  }

  li {
    float: left;
    margin-left: 1rem;
    list-style-type: none;
    font-family: 'Lato', sans-serif;
  }

  .navLeft {
    transition: .2s;
  }

  .navRight {
    margin: 1rem 1rem 1rem auto;

    font-size: 1.6em;
  }

  &.stickyHeader {
    background-color: var(--colour-primary);
    box-shadow: 0.1rem 0.1rem 0.05rem var(--colour-primary-darker);
    padding: 0.5rem;

    @media print {
      box-shadow: none;
    }
  }
}

.logo {
  display: flex;
  align-items: center;

  p {
    margin: 0 0 0 0.5rem;
    line-height: 1.2em;
  }

  &:hover {
    transform: translate(-0.04rem, -0.04rem);

    > span {
      box-shadow: 0.15rem 0.15rem var(--colour-primary-darker);
    }
    p {
      text-shadow: 0.12rem 0.12rem var(--colour-primary-darker);
    }
  }
}

.menuToggle {
  font-size: 1.8em;

  background: none;
  border: none;
  color: white;
  cursor: pointer;

  display: none;
}

@media only screen and (max-width: 600px) {
  .header {
    .navLeft {
      position: fixed;
      text-align: right;
      right: -8rem;
      padding: 0.8rem 1rem;
      top: 3rem;

      background-color: var(--colour-primary);
      font-family: 'Poppins', sans-serif;
      font-size: 1.1em;
      line-height: 1.5em;

      border-radius: 0.2rem;
      z-index: 10;

      li {
        float: none;
        margin-left: 0;
      }

      &.visible {
        right: 0rem;
      }
    }

    &.stickyHeader .navLeft {
      top: 2.5rem;
    }

    .navRight {
      margin: 1rem 0.5rem;
    }

    .menuToggle {
      display: block;
    }
  }
}

.menuToggleOff {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;

  &.visible {
    display: block;
  }
}

.activePage {
  font-weight: bold;
}