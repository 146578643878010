.linkIcon {
  position: absolute;
  background-color: #000000d0;
  color: #fff;
  font-size: 1.3em;
  transition: 0.1s;

  &:hover {
    color: #dedede;

    &.mainLink {
      svg {
        transform: rotate(0.11turn) scale(1.05);
      }
    }

    &.githubLink {
      svg {
        transform: rotate(-0.11turn) scale(1.05);
      }
    }
  }

  &.mainLink {
    padding: 3rem 4rem 0.1rem 3rem;
    left: -3rem;
    top: -2.6rem;

    transform: rotate(-0.11turn);

    svg {
      transform: rotate(0.11turn);
    }
  }

  &.githubLink {
    padding: 3rem 3rem 0.1rem 4rem;
    right: -3rem;
    top: -2.6rem;

    transform: rotate(0.11turn);

    svg {
      transform: rotate(-0.11turn);
    }
  }
}

.tags {
  margin-top: auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}